import React from "react";

type ModalContextType = {
  // openUpgrade: boolean;
  // setOpenUpgrade: (open: boolean) => void;
  openLogin: boolean;
  setOpenLogin: (open: boolean) => void;
  isLogin: boolean;
  setIsLogin: (open: boolean) => void;
};

let initialState: ModalContextType = {
  // openUpgrade: boolean,
  // setOpenUpgrade: (open: boolean) => {},
  openLogin: false,
  setOpenLogin: (open: boolean) => {},
  isLogin: false,
  setIsLogin: (open: boolean) => {},
};

const ModalContext = React.createContext(initialState);

export default ModalContext;
